import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { GatsbyImage } from 'gatsby-plugin-image'
import ReactMarkdown from 'react-markdown'

const KeyPeople = ({ keyPeople }) => {
    if (!(Array.isArray(keyPeople) && keyPeople.length)) return null
    return (
        <div className='about-key-people'>
            {keyPeople.map(k => (
                <div key={k.id}>
                    <GatsbyImage
                        image={
                            k.image.localFile.childImageSharp.gatsbyImageData
                        }
                        alt=''
                    />
                    <div className='md'>
                        <ReactMarkdown children={k.text} />
                    </div>
                </div>
            ))}
        </div>
    )
}

const PageComponent = ({ data }) => {
    const { strapiAboutPages: pageData } = data
    const __html = pageData.fields?.markdownBody?.childMarkdownRemark.html
    const backgroundImage =
        pageData.BackgroundImage?.localFile?.childImageSharp.gatsbyImageData
    const keyPeople = pageData.KeyPeople
    return (
        <Layout backgroundImage={backgroundImage}>
            <Seo title={pageData.Title} />
            <div className='md' dangerouslySetInnerHTML={{ __html }} />
            <KeyPeople keyPeople={keyPeople} />
        </Layout>
    )
}

export default PageComponent
export const pageQuery = graphql`
    query AboutPages($id: String!) {
        strapiAboutPages(id: { eq: $id }) {
            Title
            BackgroundImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
            fields {
                markdownBody {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            KeyPeople {
                id
                text
                image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                width: 200
                                height: 200
                            )
                        }
                    }
                }
            }
        }
    }
`
